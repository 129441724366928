
import { ValidationObserver } from 'vee-validate';
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
import VuePhoneNumberInput from 'vue-phone-number-input';
import { FileUploader } from '@/shared/firebase/file-uploader.js';
import { mapActions } from 'vuex';
export default {
  props: ['job'],
  components: {
    ValidationObserver,
    ValidationProvider,
    VuePhoneNumberInput,
  },
  data() {
    return {
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        phone: null,
        resume: null,
      },
      path: 'resume',
      snackbar: false,
      timeout: 3000,
      loading: false,
    };
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      var uploads = await FileUploader.uploadFile(
        this.path,
        this.formData.resume[0],
      );
      await this.doSubmitApplication({
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        email: this.formData.email,
        phoneNumber: this.formData.phone,
        resume: uploads,
        jobId: this.job.id
      });
      this.snackbar = true;
      this.loading = false;
      this.clearForm();
    },
    getInput(payload) {
      this.formData.phone = payload.formattedNumber;
    },
    clearForm() {
      this.formData.firstName = '';
      this.formData.lastName = '';
      this.formData.email = '';
      this.formData.phoneNumber = '';
      this.formData.resume = null;
    },
    ...mapActions({
      doSubmitApplication: 'careers/doSubmitApplication',
    }),
  },
};
