
import Banner from '../../home/components/banner.vue';
import Footer from '../../footer/components/footer.vue';
import JobOverview from '../../careers/components/job-overview.vue';
import JobApplication from '../../careers/components/job-application.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Banner,
    Footer,
    JobOverview,
    JobApplication,
  },
  computed: {
    isMobile() {
      return screen.width < 500;
    },
    ...mapGetters({
      job: 'careers/job',
      loading: 'careers/loading',
    }),
  },
  async created() {
    await this.doFetchVacancy(this.$route.params.id);
  },
  methods: {
    ...mapActions({
      doFetchVacancy: 'careers/doFetchVacancy',
    }),
  },
  data() {
    return {
      tab: 'overview',
    };
  },
};
